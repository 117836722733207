import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://rsvp.allgoritma.id/api/login', {
        email,
        password
      });

      const responseData = response.data;

      if (responseData.message === 'Success' && responseData.data.access_token) {
        // Simpan token di local storage
        localStorage.setItem('token', responseData.data.access_token);
        // Redirect ke halaman home atau lakukan tindakan lain setelah login berhasil
        // Misalnya: history.push('/home');
        console.log('Login successful! Token:', responseData.data.access_token);
        onLogin(responseData.data.access_token);
      } else {
        setErrorMessage('Login failed: Invalid response from server');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage('Login failed: Please check your credentials.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit">Login</button>
        </form>
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      </div>
    </div>
  );
};

export default Login;
