import React from 'react';
import { Link } from 'react-router-dom';
import { FaQrcode } from 'react-icons/fa';
import './Home.css';

const Home = ({ onLogout }) => {
  return (
    <div className="home-container">
      <h2 className="home-title">Scan Your Participant Now</h2>
      <div className="home-content">
        <p className="home-description">
          Use button Scan Qr.
        </p>
        <Link to="/scanner" className="scan-button">
          <FaQrcode className="scan-icon" />
          <span className="scan-text">Scan QR Code</span>
        </Link>
      </div>
      <button className="logout-button" onClick={onLogout}>
        Logout
      </button>
    </div>
  );
};

export default Home;
