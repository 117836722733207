import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './ui/login/Login';
import Home from './ui/home/Home';
import ScanQRCode from './ui/scanqr/ScanQRCode';

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));

  const handleLogin = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={token ? <Navigate to="/home" /> : <Login onLogin={handleLogin} />} />
        <Route path="/home" element={token ? <Home onLogout={handleLogout} /> : <Navigate to="/" />} />
        <Route path="/scanner" element={token ? <ScanQRCode /> : <Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
