import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import QrScanner from 'react-qr-scanner';
import './ScanQRCode.css';
import { FaQrcode } from 'react-icons/fa';
import axios from 'axios';
import Swal from 'sweetalert2';

const ScanQRCode = () => {
  const navigate = useNavigate();
  const [scanResult, setScanResult] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const scannerRef = useRef(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setAccessToken(storedToken);
    }

    // Start scanning when the component mounts
    startScan();
  }, []);

  const startScan = () => {
    if (scannerRef.current) {
      scannerRef.current.openImageDialog();
    }
  };

  const constraints = {
    video: { facingMode: 'environment' }
  };



  const previewStyle = {
    height: '100%',
    width: '100%',
    background: 'none'
}

  const handleScan = (data) => {
    if (data) {
      setScanResult(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while scanning the QR code. Please try again.',
    });
  };

  const handleCancel = () => {
    navigate('/');
  };

  const handleScanButtonClick = () => {
    if (scanResult && scanResult.text) {
      const requestData = {
        booking_code: scanResult.text,
      };

      axios.post('https://rsvp.allgoritma.id/api/verifikasi/booking', requestData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An error occurred while verifying the booking code. Please try again.',
          });
        });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No QR Code Scanned',
        text: 'Please scan a QR code first.',
      });
    }
  };

  return (
    <div className="scan-qr-container">
      <div className="scan-qr-header">QR Code Participant</div>
      <div className="qr-scanner">
              <QrScanner
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={previewStyle}
          className="qr-scanner"
          ref={scannerRef}
          constraints={constraints}  // Set to "environment" for using the back camera
        />

      </div>
      <div className="buttons-container">
        <button className="button cancel-button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="button back-button" onClick={handleScanButtonClick}>
          Scan <FaQrcode />
        </button>
      </div>
      {scanResult && scanResult.text && <div>Scan Result (Booking Code): {scanResult.text}</div>}
    </div>
  );
};

export default ScanQRCode;
